:root {
  /* --background-color-item: #ddd; */
  --background-color-item: #f8f9fa;
}

.sidebar-logo {
  /* padding: 1rem; */
  /* padding: 2rem 4rem; */
  padding: 2rem 2rem;
  height: 7rem;
}

.list-group-item {
  border: none;
  /* text-align: center; */
  color: #ffffff;
  background-color: #5a6268;
  border-color: #5a6268;

  /* Новый Вариант */
  /* text-align: left; */
  display: flex;
  padding: 0 1.25rem;
  height: 2.75rem;
  align-items: center;
  justify-content: flex-start;
}

.list-group-item svg {
  /* display: flex; */
  margin-right: 0.5rem !important;
}

.list-group-item.active {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.list-group-item:hover {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.list-group-item.notification::after {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  content: "\f111";
  font-size: 0.5rem;
  margin-left: .5rem;
}

.notification-active {
  position: absolute;
  right: 1rem;
  top: 1.2rem;
  font-size: 0.5rem;
}

.input-border {
  border-color: #6c757d;
  border-bottom: 1px;
}

/* Home */

.pricing-table .col-pricing-table .plan-header {
  background: #2c323f;
  color: #fff;
  border-radius: .3rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  width: 100%;
  margin: 0;
  padding: 1.2rem 0;
}
.pricing-table .col-pricing-table ul.plan-feature-list{
  list-style: none;
  /* padding: 1rem 2rem; */
  margin-bottom: 0;
  border-bottom: 0.1rem solid #e0e3eb;
}
.pricing-table .col-pricing-table h2.plan-price {
  color: #2c323f;
  font-size: 2rem;
  margin: 0;
  /* padding: 1rem 0; */
  font-weight: 700;
  width: 100%;
}
.pricing-table .col-pricing-table p.emphasis {
margin: .2rem 0 0 0;
color: #a3acc2;
}

/* Login Component */
.form-signin {
width: 100%;
max-width: 330px;
/* padding: 15px; */
padding-top: 7rem;
padding-bottom: 7rem;
margin: auto;
}

.text-center {
text-align: center!important;
}

.form-control-custom {
border: 0.1rem solid #dfe1e5;
width: 100%;
padding: 1rem;
font-size: .9rem;
color: #696b6e;
border-radius: .3rem;
}

.form-control-custom:focus {
border: 0.1rem solid #59a0d7;
outline: none;
}

/* Categories component */


.form-control-categories {
border: 0.1rem solid #dfe1e5;
width: 100%;
padding: .8rem;
font-size: .9rem;
color: #696b6e;
border-radius: .3rem;
}

.form-control-categories:focus {
border: 0.1rem solid #59a0d7;
outline: none;
}

.form-button {
  margin-right: 1rem;
}

.editor-main, .DraftEditor-editorContainer {
  border: none !important;
  border-radius: 0;
}

.editor-main span {
  font-size: 1.2rem;
  line-height: 1.25;
  color: #495057;
}

.toolbar-category-description {
  display: none;
}

.public-DraftEditor-content {
  padding: 0 !important;
  /* padding-left: 0 !important; */
}



/* Collections component */

.collection-item {
  margin-bottom: 30px;
  border: none;

  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: none;
  border-radius: 0.25rem;
}

.collection-img-overlay {
  background-color: rgba(0,0,0,0.2);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* padding-right: 15px;
  padding-left: 15px; */
  /* margin-left: 15px; */
  /* margin-right: 15px; */
  text-align: center;
  /* border-radius: 0.25rem; */
  border-radius: 20px;
/* padding: 1.25rem; */

-webkit-transition: all 0.3s ease-in-out;
-moz-transition: all 0.3s ease-in-out;
-ms-transition: all 0.3s ease-in-out;
-o-transition: all 0.3s ease-in-out;
transition: all 0.1s ease-in-out;
}
.collection-img-overlay:hover {
  background-color: rgba(0,0,0,0.3);
  /* opacity: 1; */
}

.collection-title {
  /* margin-top: 37%; */

  /* margin-top: -moz-calc(100% - (20px + 30px));
  margin-top: -webkit-calc(100% - (20px + 30px));
  margin-top: calc(100% - (20px + 30px)); */

  /* margin-top: -moz-calc(100% - 55%);
  margin-top: -webkit-calc(100% - 55%);
  margin-top: calc(100% - 55%); */
  /* margin-top: 40%;
  margin-bottom: 40%; */

/* margin-bottom: 0.75rem; */
  position: absolute;
  left: 0;
  right: 0;
  bottom: 38%;
  color: #fff;
}


/* .flex-modifier {
  flex-direction: row;
  display: flex;
} */

/*
CollectionPage component
*/

/* .input-test {
  border: none;
  outline: 0;
  padding: 0.25rem .1rem;
  width: 100%;
  font-size: 1.2rem;
  color: #464a4c;
} */

.input-main {
  border: none;
  outline: 0 !important;
  padding: 0.25rem .1rem !important;
  /* width: 100%; */
  font-size: 1.2rem;
  color: #464a4c;

  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
}

.input-main:focus {
  outline: 0 !important;
}

.input-main-collection-page {
  border: none;
  outline: 0 !important;
  padding: 0.25rem .1rem !important;
  /* width: 100%; */
  font-size: 1.2rem;
  color: #464a4c;
}

.input-title {
  font-size: 1.2rem;
  color: #464a4c;
}

.input-text-example {
  padding: 0.25rem .1rem;
  font-size: .8rem;
  margin-bottom: 0 !important;
}

.image-item {
  /* margin-bottom: 30px; */
  border: none;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: none;
  border-radius: 0.25rem;
}

.image-img-overlay {
  opacity: 0;
  background-color: rgba(0,0,0,0.2);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* padding-right: 15px;
  padding-left: 15px; */
  /* margin-left: 15px; */
  /* margin-right: 15px; */
  text-align: center;
  /* border-radius: 0.25rem; */
  border-radius: 20px;
  /* padding: 1.25rem; */

-webkit-transition: all 0.3s ease-in-out;
-moz-transition: all 0.3s ease-in-out;
-ms-transition: all 0.3s ease-in-out;
-o-transition: all 0.3s ease-in-out;
transition: all 0.1s ease-in-out;
}
.image-img-overlay:hover {
  background-color: rgba(0,0,0,0.3);
  opacity: 1;
}

.image-title {
  /* margin-top: 37%; */
  position: absolute;
  left: 0;
  right: 0;
  bottom: 40%;
/* margin-bottom: 0.75rem; */
  color: #fff;
}


/*
Category Select component
*/
.input-category {
  border: none;
  outline: 0;
  padding: 0.25rem .1rem;
  width: 100%;
  font-size: 1.2rem;
  color: #464a4c;
  cursor: pointer;
}

.autocomplete-container {
  position: absolute;
  z-index: 9999;
  border: 1px solid #dfdfdf;

  /* New Version more  compact (small) */
  /* margin-left: 1rem; */
  width: 70%;
}

.autocomplete-item {
  background-color: rgb(255, 255, 255);
  /* padding: 1rem; */
  padding: .8rem;
  color: rgb(85, 85, 85);
  cursor: pointer;
}

.autocomplete-text {
  background-color: rgb(255, 255, 255);
  color: rgb(85, 85, 85);
  padding: .5rem 1rem;
}

.autocomplete-item:hover, .autocomplete-item.active {
  background-color: rgb(250, 250, 250);
  -webkit-transition: all .1s ease-in-out;
}
.rounded {
  border-radius: 20px !important;
}

.rounded-20 {
  border-radius: 20px !important;
}

.rounded-12 {
  border-radius: 12px !important;
}

/*
Gallery
 */
 .modal-custom{
   max-width: 80%;
 }

.modal-custom .modal-content{
  border: none;
  border-radius: 20px;
}

.modal-custom .modal-header{
  border-bottom: none;
}

.modal-custom .modal-footer{
  border-top: none;
}

/* Change modal animation */

.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}
.modal.fade .modal-dialog {
  transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  -webkit-transform: none;
  transform: none;
}

/* Custom Controls */
.custom-control {
  margin-bottom: 0 !important;
}
.custom-control.material-checkbox {
	 --color: #26a69a;
}
 .custom-control.material-checkbox .material-control-input {
	 display: none;
}
 .custom-control.material-checkbox .material-control-input:checked ~ .material-control-indicator {
	 border-color: var(--color);
	 transform: rotateZ(45deg) translate(1px, -5px);
	 width: 10px;
	 border-top: 0px solid #fff;
	 border-left: 0px solid #fff;
}
 .custom-control.material-checkbox .material-control-indicator {
	 display: inline-block;
	 position: absolute;
	 top: 4px;
	 left: 0;
	 width: 16px;
	 height: 16px;
	 border: 2px solid #aaa;
	 transition: 0.3s;
}

.custom-control.material-switch {
  --color: #6c757d;
  padding-left: 0;
}
.custom-control.material-switch .material-switch-control-input {
  display: none;
}
.custom-control.material-switch .material-switch-control-input:checked ~ .material-switch-control-indicator::after {
  background-color: var(--color);
  left: 17px;
}
.custom-control.material-switch .material-switch-control-indicator {
  display: inline-block;
  position: relative;
  margin: 0 10px;
  top: 4px;
  width: 32px;
  height: 16px;
  background: #ddd;
  border-radius: 16px;
  transition: 0.3s;
}
.custom-control.material-switch .material-switch-control-indicator::after {
  content: '';
  display: block;
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  transition: 0.3s;
  top: -1px;
  left: -1px;
  /* background: #fdfdfd; */
  background: #f7f7f7;
  /* border: 1px solid #ddd; */
  /* box-shadow: 0 2px 2px #aaa; */
}

.badge {
  padding: 0.5em 0.5em;
}

.feature-item {
  margin-right: .5rem;
  margin-bottom: .5rem;
  font-size: 85%;
}

.feature-item-remove {
  content: "x";
  padding: 0px 2px;
  cursor: pointer;
}

.care-item {
  margin-right: .5rem;
  margin-bottom: .5rem;
  font-size: 85%;
}

.care-item-remove {
  content: "x";
  padding: 0px 2px;
  cursor: pointer;
}

.loading-placeholder {
  animation: pulse 1s infinite ease-in-out;
}

.loading-placeholder-svg rect{
  animation: pulse 1s infinite ease-in-out;
}

/* PlaceholderLine */
.fa-square-full-3:before {
content: "\f45c\f45c\f45c";
}
.fa-square-full-4:before {
content: "\f45c\f45c\f45c\f45c";
}
.fa-square-full-5:before {
content: "\f45c\f45c\f45c\f45c\f45c";
}
.fa-square-full-6:before {
content: "\f45c\f45c\f45c\f45c\f45c\f45c";
}
.fa-square-full-7:before {
content: "\f45c\f45c\f45c\f45c\f45c\f45c\f45c";
}
.fa-square-full-8:before {
content: "\f45c\f45c\f45c\f45c\f45c\f45c\f45c\f45c";
}
.fa-square-full-9:before {
content: "\f45c\f45c\f45c\f45c\f45c\f45c\f45c\f45c\f45c";
}
.fa-square-full-10:before {
content: "\f45c\f45c\f45c\f45c\f45c\f45c\f45c\f45c\f45c\f45c";
}
.fa-square-full-11:before {
content: "\f45c\f45c\f45c\f45c\f45c\f45c\f45c\f45c\f45c\f45c\f45c";
}
.fa-square-full-12:before {
content: "\f45c\f45c\f45c\f45c\f45c\f45c\f45c\f45c\f45c\f45c\f45c\f45c";
}
.fa-square-full-13:before {
content: "\f45c\f45c\f45c\f45c\f45c\f45c\f45c\f45c\f45c\f45c\f45c\f45c\f45c";
}
.fa-square-full-14:before {
content: "\f45c\f45c\f45c\f45c\f45c\f45c\f45c\f45c\f45c\f45c\f45c\f45c\f45c\f45c";
}
.fa-square-full-15:before {
content: "\f45c\f45c\f45c\f45c\f45c\f45c\f45c\f45c\f45c\f45c\f45c\f45c\f45c\f45c\f45c";
}

@keyframes pulse {
    0% {
        color: rgba(165, 165, 165, 0.1);
        fill: rgba(165, 165, 165, 0.1);
    }
    50% {
        color: rgba(165, 165, 165, 0.3);
        fill: rgba(165, 165, 165, 0.3);
    }
    100% {
        color: rgba(165, 165, 165, 0.1);
        fill: rgba(165, 165, 165, 0.1);
    }
}


/* Search stock element */

.input-main-icon {
  background-color: white;
  border: none;
  border-radius: 0;
  /* padding: 1rem; */
  /* padding: 0.375rem 0.75rem; */
  padding: 0.375rem 0.5rem;
}

.darkgray {
  color: darkgray;
}

/* input, input:focus{
  outline:0 !important;
  -webkit-appearance:none;
}

.input-group, .input-group:focus {
  outline: 0 !important;
  -webkit-appearance:none;
} */



/* Stock Item */
.imageStockItem {
  max-width: 100px;
  margin-left: 1rem;
}

/* Lead Component */

.lead-block {
  display: flex;
  flex: 0 0 95%;
  flex-direction: row;
  max-width: 95%;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 20px;
  margin-bottom: 1rem;
}

.lead-block .lead-item-title {
  flex: 0 0 10%;
  max-width: 10%;
  position: relative;
  /* padding-left: 1rem; */
}

.lead-block .lead-item-description {
  flex: 0 0 33%;
  max-width: 33%;
  position: relative;
}

.lead-block .lead-item-message {
  flex: 0 0 45%;
  max-width: 45%;
  position: relative;
}

.lead-block .lead-item-controls {
  flex: 0 0 12%;
  flex-direction: column;
  align-items: flex-end;
  max-width: 12%;
  position: relative;
  text-align: right;
}

.lead-block .lead-item-controls .button-process {
  display: flex;
  position: relative;
  border-radius: 12px;
  margin-bottom: .5rem;
  padding: 0.5rem 0.5rem;
}


/*
  Image progressive downloading and placeholder
 */

 .intrinsic {
  display: block;
  position: relative;
  height: 0;
  width: 100%;
  background: rgba(165, 165, 165, 0.1);
  /* background: #f0f0f0; */
 }

.intrinsic--4x3 {
  padding-top: 75%;
}

.intrinsic-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/*
  Color buttons
*/

.color-button {
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: .9;
  border: 1px solid transparent;
  background-color: transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.color-button span {
  color: #495057;
}

.color-button:hover {
  cursor: pointer;
  opacity: .7;
}

.data-table-main header {
  display: none;
}

.layout {
  display: flex;
}

.sidebar-container {
  position: fixed;
  height: 100vh;
  width: 250px;
  /* background-color: #343a40 !important; */
  background-color: #5a6268;
}

.sidebar-container .col-md-12 {
  padding-left: 0;
  padding-right: 0;
}

.main-container {
  margin-left: 250px;
  overflow: hidden;
  flex: 1;
  max-width: calc(100vw - 250px);
  min-height: 100vh;
}

.page-header {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  /* padding-bottom: 1rem; */
  /* border-bottom: 1px solid #eee; */
  height: auto;
}

.page-header h1 {
  display: inline;
  border: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
}

.profile-photo {
  border-radius: 499rem;
  float: left; }

.username {
  float: left;
  text-align: left;
  margin: 0 0 0 1rem; }
.username h1, .username h2, .username h3, .username h4 {
  font-size: 1.25rem;
  margin-bottom: 0;
  text-transform: none !important;
  letter-spacing: 0 !important;
}

.username h4 {
  color: #5a6268;
}

.username h4:hover {
  color: #6c757d;
}

.username h6 {
  font-size: .9rem;
}

.user-dropdown .dropdown-toggle::after {
  outline: none;
  margin-top: 1rem;
  margin-left: 1rem; }

/* .user-dropdown a, .user-dropdown a:hover, .user-dropdown a:focus, .user-dropdown a:active {
  outline: none !important; } */

*:focus {
  outline: none;
}

.breadcrumb-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 3.5625rem;
}

/*
  Handling Component
 */

 .handling-locked {
 /* width: 100%; */
 height: 100vh;
 /* max-width: 330px; */
 /* padding: 15px; */
 /* padding-top: 7rem;
 padding-bottom: 7rem; */
 margin: auto;

 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
}

.pad {
  max-width: 330px;
}

.pad .pad-digit {
  border-radius: 12px;
  background-color: #f8f9fa;
  /* min-height: 4.25rem; */
  font-size: 1.5rem;
}

.pad-placeholder {
  color: #f8f9fa;
}

.pad .pad-button {
  height: 5rem;
  width: 5rem;
  font-size: 2rem;
  border-radius: 20px;
}

/* Requests Page */

.requests-block {
  display: flex;
  flex: 0 0 95%;
  flex-direction: row;
  max-width: 95%;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 20px;
  margin-bottom: 1rem;
}

.requests-block .requests-item-title {
  flex: 0 0 40%;
  max-width: 40%;
  position: relative;
  /* padding-left: 1rem; */
}

.requests-block .requests-item-description {
  flex: 0 0 40%;
  max-width: 40%;
  position: relative;
}

.requests-block .requests-item-message {
  flex: 0 0 20%;
  max-width: 20%;
  position: relative;
}

.requests-block .requests-item-controls {
  flex: 0 0 12%;
  flex-direction: column;
  align-items: flex-end;
  max-width: 12%;
  position: relative;
  text-align: right;
}

.requests-block .requests-item-controls .button-process {
  display: flex;
  position: relative;
  border-radius: 12px;
  margin-bottom: .5rem;
  padding: 0.5rem 0.5rem;
}

/* Staff Component */

.staff-block {
  display: flex;
  flex: 0 0 95%;
  flex-direction: row;
  max-width: 95%;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 20px;
  margin-bottom: 1rem;
  min-height: 140px;
}

.staff-block .staff-item-photo {
  flex: 0 0 8%;
  max-width: 8%;
  position: relative;
  /* padding-left: 1rem; */
}

.staff-block .staff-item-description {
  flex: 0 0 40%;
  max-width: 40%;
  position: relative;
}

.staff-block .staff-item-message {
  flex: 0 0 40%;
  max-width: 40%;
  position: relative;
}

.staff-block .staff-item-controls {
  flex: 0 0 12%;
  flex-direction: column;
  align-items: flex-end;
  max-width: 12%;
  position: relative;
  text-align: right;
}

.pinCodeButton {
  color: #5a6268;
  cursor: pointer;
}

.pinCodeButton:hover {
  color: #6c757d;
}

.full-width {
  width: 100%;
}

.imageStockItemInRequestPage {
  max-width: 75px;
  margin-left: 1rem;
}

.imageFabricItem {
  /* max-width: 75px; */
  /* margin-left: 1rem; */
}

.nameFabricItem{
  display: flex;
  text-align: left;
  height: 100%;
}

.nameFabricItem p {
  margin: auto;
  width: 100%;
}

.valueFabricItem {
  display: flex;
  text-align: left;
  height: 100%;
}

.universalFabricItem {
  display: flex;
  text-align: right;
}

/* Handling Block */

.handling-block {
  display: flex;
  /* flex: 0 0 90%; */
  /* flex-direction: row; */
  /* max-width: 90%; */
  /* margin-bottom: 1rem; */
}

.handling-row .handling-block:not(:last-child) {
  margin-bottom: 1rem;
}

.handling-block .handling-item-image {
  flex: 0 0 8%;
  max-width: 8%;
  position: relative;
  /* padding-left: 1rem; */
}

.handling-block .handling-item-title {
  flex: 0 0 25%;
  max-width: 25%;
  position: relative;
  background-color: #f8f9fa;
  border-radius: 20px;
  padding: 1.5rem;
}

.handling-block .handling-item-title h5 {
  margin-bottom: 0;
}

.handling-block .handling-item-rolls-sum {
  flex: 0 0 30%;
  max-width: 30%;
  position: relative;
  background-color: #f8f9fa;
  border-radius: 20px;
  padding: 1.5rem;
}

.handling-block .handling-item-rolls-sum p {
  margin-bottom: 0;
}

.handling-block .handling-item-message {
  flex: 0 0 20%;
  max-width: 20%;
  position: relative;
}

.handling-block .handling-item-controls {
  flex: 0 0 12%;
  flex-direction: column;
  align-items: flex-end;
  max-width: 12%;
  position: relative;
  text-align: right;
}

.handling-block .handling-item-controls .button-process {
  display: flex;
  position: relative;
  border-radius: 12px;
  margin-bottom: .5rem;
  padding: 0.5rem 0.5rem;
}

.roll-block {
  display: flex;
  /* flex: 0 0 90%; */
  flex-direction: row;
  /* max-width: 90%; */
  margin-bottom: 1rem;
}

.roll-title {
  flex: 0 0 14%;
  max-width: 14%;
  position: relative;
  background-color: #f8f9fa;
  border-radius: 20px;
  padding: 1.5rem;
  /* margin-left: 10%; */
}

.roll-title h5 {
  margin-bottom: 0;
}

.roll-item {
  display: flex;
  position: relative;
  border-radius: 20px;
  margin-left: 1rem;
  padding: 1.5rem;
}

.roll-item h5 {
  margin-bottom: 0;
}

.roll-item-with-input {
  display: flex;
  position: relative;
  border-radius: 20px;
  margin-left: 1rem;
  max-width: 6rem;
  /* padding: 1.5rem; */
}


.roll-cut {
  /* flex: 0 0 25%; */
  display: flex;
  /* max-width: 25%; */
  position: relative;
  /* background-color: #f8f9fa; */
  border-radius: 20px;
  /* padding: 1.5rem; */
  margin-left: 1rem;
}

.roll-cut button {
  border-radius: 20px;
}

.roll-otval {
  display: flex;
  position: relative;
  padding: 1.5rem;
  /* margin-left: 1rem; */
}

.roll-otval h5 {
  margin-bottom: 0;
}

/* .roll-block .roll-title:not(:last-child) {
  margin-bottom: 1rem;
} */

.input-cut {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  border: none;
  outline: 0 !important;
  padding: 0.25rem 1rem !important;
  /* padding: 0; */
  width: 100%;
  font-size: 1.5rem;
  color: #464a4c;
  background-color: var(--background-color-item);
  /* background-color: #f8f9fa; */
  /* width: 1%; */
  border-radius: 20px;
}

.input-cut:focus {
  outline: 0 !important;
}

/*
  ClientsItem
 */

.client-block {
 display: flex;
 /* flex: 0 0 90%; */
 flex-direction: row;
 /* max-width: 90%; */
 margin-bottom: 1rem;

 background-color: #f8f9fa;
 border-radius: 20px;
}

.client-title {
 /* flex: 0 0 50%; */
 /* max-width: 50%; */
 position: relative;
 padding: 1rem;
 /* padding: 1.5rem; */
 /* margin-left: 10%; */
}

.client-title p {
  color: #5a6268;
  margin-bottom: 0;
}

.client-title a {
  /* color: #5a6268; */
  color: rgba(0, 0, 0, 0.8);
  text-decoration: none;
  font-size: 1.25rem;
}

.client-title a:hover {
  color: #5a6268;
  /* color: #6c757d; */
}

.client-item {
 display: flex;
 position: relative;
 margin-left: 1rem;
 padding: 1.5rem;
}


/*
  Supplied Fabrics Component
 */

 .suppliedFabrics-block {
   display: flex;
   /* flex: 0 0 90%; */
   flex-direction: row;
   /* max-width: 90%; */
   margin-bottom: 1rem;
 }

 .suppliedFabrics-image {
   display: flex;
   /* flex: 0 0 13%; */
   /* max-width: 13%; */
   position: relative;
   /* background-color: #f8f9fa; */
   border-radius: 12px;
   /* padding: 1.5rem; */
   /* margin-left: 10%; */
 }

 .suppliedFabrics-title {
   flex: 0 0 20%;
   max-width: 20%;
   position: relative;
   /* background-color: #f8f9fa; */
   background-color: var(--background-color-item);
   border-radius: 20px;
   padding: 1.5rem;
   /* margin-left: 10%; */
 }

 .suppliedFabrics-title h5, .suppliedFabrics-title p {
   margin-bottom: 0;
 }

 .suppliedFabrics-item {
   display: flex;
   position: relative;
   border-radius: 20px;
   margin-left: 1rem;
   padding: 1.5rem;
 }

 .suppliedFabrics-item h5, .suppliedFabrics-item p {
   margin-bottom: 0;
 }

 .suppliedFabrics-item-with-input {
   display: flex;
   position: relative;
   border-radius: 20px;
   margin-left: 1rem;
   max-width: 6rem;
   /* padding: 1.5rem; */
 }

 .suppliedFabrics-item-with-input-qty {
   display: flex;
   position: relative;
   border-radius: 20px;
   margin-left: 1rem;
   max-width: 4rem;
   /* padding: 1.5rem; */
 }

 .suppliedFabrics-text {
   display: flex;
   position: relative;
   /* border-radius: 20px; */
   margin-left: 1rem;
   padding: 1.5rem 0 1.5rem 0;
 }

 .suppliedFabrics-text h5, .suppliedFabrics-text p {
   margin-bottom: 0;
 }

 /*
 Add Store Component
  */

/* .input-phone {
  border: none;
  outline: 0 !important;
  padding: 0.25rem .1rem !important;
  max-width: 7.5rem;
  font-size: 1.2rem;
  color: #464a4c;
} */

.input-phone {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  /* background-color: #fff; */
  /* background-color: var(--background-color-item); */
  background-clip: padding-box;
  border: 1px solid #fff;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-phone-sm {
  /* padding: 0.25rem 0;
  font-size: 0.875rem;
  line-height: 1.5; */
  padding: 0;
  font-size: 1.25rem;
  line-height: 1.5;
}


/*
  Neo concept fabric item in select fabrics
 */

.table-fabrics-list td {
  vertical-align: middle;
}

.table-fabrics-list .image-cell {
  max-width: 3rem;
}

.table-fabrics-list .controls-cell {
  max-width: 3rem;
}

.table-fabrics-list .controls-cell .btn{
  border-radius: 12px;
}

.table-fabrics-list .main-cell {
  justify-content: space-between;
}

.table-fabrics-list .main-cell .history-prices-list {
  list-style: none;
  padding-left: 1rem;
}

/* last version */
/* .table-fabrics-list .name-cell {
  min-width: 10rem;
  max-width: 10rem;
}

.table-fabrics-list .price-cell {
  min-width: 10rem;
  max-width: 10rem;
  text-align: right;
}

.table-fabrics-list .price-cell .history-prices-list{
  list-style: none;
  padding-left: 1rem;
} */

.fabric-image-item {
  max-width: 75px;
  margin-left: 1rem;
}

.fabric-image-text {
  display: flex;
  text-align: left;
  height: 100%;
  background-color: var(--background-color-item);
  padding-left: 1rem;
  padding-right: 1rem;
}

.fabric-item-input {
  max-width: 7rem;
}

/*
.nameFabricItem p {
  margin: auto;
  width: 100%;
}

.valueFabricItem {
 display: flex;
 text-align: left;
 height: 100%;
}

.universalFabricItem {
 display: flex;
 text-align: right;
} */

/*
  Deliveries Page
*/

.delivery-block {
 display: flex;
 flex: 0 0 100%;
 flex-direction: row;
 max-width: 100%;

 background-color: #f8f9fa;
 border-radius: 20px;
 margin-bottom: 1rem;
}

.delivery-title {
 /* flex: 0 0 50%; */
 /* max-width: 50%; */
 position: relative;
 padding: 1rem;
 /* padding: 1.5rem; */
 /* margin-left: 10%; */
}

.delivery-title p, .delivery-item p {
  /* color: #5a6268; */
  margin-bottom: 0;
}

.delivery-title a {
  /* color: #5a6268; */
  color: rgba(0, 0, 0, 0.8);
  text-decoration: none;
  font-size: 1.25rem;
}

.delivery-title a:hover {
  color: #5a6268;
  /* color: #6c757d; */
}

.delivery-item {
 display: flex;
 position: relative;
 flex-direction: column;
 /* margin-left: 1rem; */
 padding: 1rem;
}

.w-40 {
  width: 40%;
}


/*
  PriceListsPage
*/

.priceList-list {
  margin-bottom: 0;
  padding-left: 1rem;
}

.priceList-list-item {
  color: #5a6268;
  display: inline-block;
  vertical-align: middle;
  /* font-size: 1.5rem; */
  /* font-weight: 500; */
  background-color: transparent;
  border-radius: 12px;
  list-style: none;
  cursor: pointer;
}

/* .priceList-list-item:hover {

} */

.priceList-list-item.active {
  color: #fff;
  background-color: #5a6268;
  cursor: default;
  transition-duration: 200ms;
}

.category-item-name{
  /* padding: .5rem 1rem;   */
  padding: .25rem .75rem;
}

.input-group-priceList{
  align-items: center;
}

.priceList-lines-wrapper {
  flex-direction: column;
}

.priceList-line {
  margin-bottom: .75rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.priceList-line__left {
  display: flex;
  align-items: center;
}

.priceList-line__right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.priceList-line__right .input-wrapper {
  display: inline-flex;
}

.priceList-line__right .input-wrapper input {
  border: none;
  outline: 0 !important;
  padding: 0.25rem 1rem !important;
  font-size: 1.5rem;
  color: #464a4c;
  background-color: var(--background-color-item);
  max-width: 7rem;
}

.priceList-line__right .controls {
  display: inline-flex;
}

.priceList-line__right .controls .btn-control {
  height: 42px;
  width: 42px;
  padding: 0;
}

.priceList-line__right .history-prices-list {
  list-style: none;
  padding-left: 0;
  min-width: 20rem;
}

.priceList-line__right .history-prices-list li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* .priceList-line__right .history-prices-list li .price {
  width: 20%;
}

.priceList-line__right .history-prices-list li .date {
  width: 80%;
} */